module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166134415-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jean-Bertrand Portfolio","short_name":"JBU","start_url":"/","background_color":"#2B3E51","theme_color":"#03C9BA","display":"standalone","icon":"src/images/Icon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"68a6824123f8d4e9b9fba8b16327148a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html","/offline-plugin-app-shell-fallback"],"crumbLabelUpdates":[{"pathname":"/portfolio","crumbLabel":"Portfolio"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
